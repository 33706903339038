import { Box, BoxProps, Heading, SimpleGrid, Skeleton } from '@chakra-ui/react'
import { range } from 'ramda'
import { JobCard } from 'components/job/JobCard'
import { JobCardData } from 'components/job/queries'

export const COLUMNS_AT_MEDIUM_SCREEN_SIZE = 2

const DEFAULT_PAGE_SIZE = 6

export const JobsGrid = ({
    title,
    jobs,
    loading,
    loadingPageSize,
    ...boxProps
}: {
    title?: string
    jobs?: JobCardData[]
    loading: boolean
    loadingPageSize?: number
} & BoxProps) => {
    const hasJobs = Boolean(jobs?.length)

    if (!loading && !hasJobs) {
        return null
    }

    const pageSize =
        (loading ? loadingPageSize : undefined) ?? jobs?.length ?? DEFAULT_PAGE_SIZE

    return (
        <Box {...boxProps}>
            {title && (
                <Skeleton isLoaded={hasJobs} maxW={{ md: '50%' }}>
                    <Heading as="h5" size="sm" mb="16px">
                        {title}
                    </Heading>
                </Skeleton>
            )}
            <SimpleGrid
                spacing={{ base: '16px', md: '24px' }}
                mb={{ base: '16px', md: '24px' }}
                columns={{ base: 1, md: COLUMNS_AT_MEDIUM_SCREEN_SIZE }}
            >
                {range(0, pageSize).map(idx => {
                    if (!hasJobs) {
                        return <JobCard key={idx} />
                    } else if (jobs && jobs[idx]) {
                        return <JobCard key={jobs[idx]?.id ?? idx} job={jobs[idx]} />
                    }
                    return null
                })}
            </SimpleGrid>
        </Box>
    )
}
