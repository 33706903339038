import { Flex, FlexProps, Switch, Text } from '@chakra-ui/react'
import { slugify } from 'lib/text'

export const ToggleInput = ({
    labelText,
    onToggle,
    isChecked,
    ...flexProps
}: {
    labelText: string
    onToggle?: () => void
    isChecked?: boolean
} & FlexProps) => {
    const id = slugify(labelText)

    return (
        <Flex
            alignItems="center"
            gap="8px"
            p="10px 16px"
            maxHeight={{ base: '40px', md: '44px' }}
            borderWidth="1px"
            borderRadius="12px"
            borderColor="gray.dividers"
            backgroundColor="white"
            cursor="pointer"
            {...flexProps}
        >
            <Text
                as={'label'}
                htmlFor={id}
                variant="bodyTextSmall"
                fontWeight="medium"
                cursor="pointer"
                whiteSpace="nowrap"
            >
                {labelText}
            </Text>
            <Switch
                id={id}
                isChecked={isChecked}
                onChange={onToggle}
                size="md"
                colorScheme="secondary"
            />
        </Flex>
    )
}
