import { ReactNode } from 'react'
import {
    Button,
    Icon,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverContentProps,
    PopoverTrigger,
} from '@chakra-ui/react'
import { ChevronDown } from 'emotion-icons/feather'

export const FilterInput = ({
    label,
    children,
    ...rest
}: {
    label: string
    children?: ReactNode
} & PopoverContentProps) => {
    return (
        <Popover placement="bottom-start">
            {({ isOpen }) => (
                <>
                    <PopoverTrigger>
                        <Button
                            size="lg"
                            variant="secondary"
                            colorScheme="secondary"
                            flexGrow="1"
                            p="10px 8px 10px 16px"
                            minWidth="fit-content"
                            maxHeight={{ base: '40px', md: '44px' }}
                            rounded="12px"
                            justifyContent="space-between"
                            color={isOpen ? 'secondary.500' : 'inherit'}
                            borderColor={isOpen ? 'secondary.500' : 'gray.dividers'}
                            fontSize="16px"
                            fontWeight="medium"
                            _hover={{
                                color: 'secondary.500',
                                borderColor: 'secondary.500',
                            }}
                            _focus={{
                                boxShadow: 'none',
                                borderColor: 'secondary.500',
                                color: 'secondary.500',
                            }}
                            rightIcon={
                                <Icon
                                    transition="all .1s"
                                    transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
                                    as={ChevronDown}
                                    strokeWidth="2px"
                                    fontSize="22px"
                                />
                            }
                        >
                            {label}
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent
                        width={{ base: 'calc(100vw - 24px)', md: 'auto' }}
                        mx={{ base: '12px', md: '0' }}
                        borderRadius="12px"
                        {...rest}
                    >
                        <PopoverBody p="24px">{children}</PopoverBody>
                    </PopoverContent>
                </>
            )}
        </Popover>
    )
}
