export type TagId = string

export const AllTagTypes = {
    benefit: 'benefit',
    tech: 'tech',
    company: 'company',
    industry: 'industry',
    funding: 'funding',
} as const

export type TagType = typeof AllTagTypes[keyof typeof AllTagTypes]

export type Tag = {
    id: TagId
    label: string
    slug: string
    type: TagType
    weight?: number
    subtype?: string
}

export type BlogTag = Pick<Tag, 'id' | 'label' | 'slug'>

export type VerticalConfigTag = Pick<Tag, 'label' | 'slug'>

export type VerticalAssociationTag = Pick<Tag, 'id' | 'label'>

export type AlgoliaTag = Pick<Tag, 'id' | 'label' | 'slug' | 'weight'>

export function sortTagsByHighestWeighted<T extends Pick<Tag, 'weight'>>(tags: T[]) {
    return tags.slice().sort((t1, t2) => {
        const w1 = t1.weight || 0
        const w2 = t2.weight || 0
        return w2 - w1
    })
}
