import { ResultOf } from '@graphql-typed-document-node/core'
import { gql } from 'urql'
import { Company } from 'entities/company'
import { Job } from 'entities/job'
import { Tag } from 'entities/tag'

export const AffiliateFragment = gql`
    fragment AffiliateFragment on Affiliate {
        source
        url
    }
`

export const FRAGMENT_JOB_CARD = gql<
    Pick<
        Job,
        | 'id'
        | 'title'
        | 'slug'
        | 'lastModified'
        | 'remote'
        | 'roleType'
        | 'remuneration'
        | 'tagline'
        | 'location'
        | 'published'
        | 'matchingUsers'
        | 'userScore'
        | 'affiliate'
    > & {
        // fetch weight for sorting
        tags: Pick<Tag, 'id' | 'slug' | 'label' | 'weight'>[]
        company: Pick<Company, 'logo' | 'size' | 'name'>
    }
>`
    fragment JobCard on Job {
        id
        title
        slug
        lastModified
        remote
        roleType
        tagline
        published
        remuneration {
            competitive
            min
            max
            currency
            timePeriod
            equity
        }
        tags {
            id
            slug
            label
            weight
        }
        company {
            id
            name
            logo
            size
        }
        location {
            street
            city
            country
            countryCode
            state
            postCode
            longitude
            latitude
        }
        matchingUsers
        userScore
        affiliate {
            ...AffiliateFragment
        }
    }
    ${AffiliateFragment}
`

export type JobCardData = ResultOf<typeof FRAGMENT_JOB_CARD>
