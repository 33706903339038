import { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { isEmpty, pickBy } from 'ramda'
import {
    AllRemunerationTimePeriods,
    AllRoleTypes,
    RemunerationTimePeriod,
    RoleType,
} from 'entities/job'
import { Packages } from 'entities/packages'
import { routes } from 'routes'
import { isServerSide } from 'lib/env'
import { notNullOrUndefinedOrEmpty } from 'lib/predicates'
import { decodeValue, decodeArray, decoders } from 'lib/url'

// Those params that go into `QUERY_SEARCH_JOBS`
export type SearchParams = {
    search?: string[]
    remote?: true
    sponsorshipOffered?: true
    tags?: string[]
    roleTypes?: RoleType[]
    // location
    locationCountryCode?: string[]
    locationCity?: string[]
    locationRegion?: string[]
    // remuneration
    remunerationCompetitive?: true
    remunerationMin?: number
    remunerationCurrency?: string
    remunerationTimePeriod?: RemunerationTimePeriod
    // Admin-specific
    companyPackages?: string[]
    manager?: string
    published?: true
}

export type Params = SearchParams & {
    // UI-specific pagination
    page?: number
}

export const queryParamName = {
    remote: 'remote',
    sponsorshipOffered: 'sponsorship-offered',
    tags: 'tags',
    roleTypes: 'role-types',
    // location
    locationCountryCode: 'location.country-code',
    locationCity: 'location.city',
    locationRegion: 'location.region',
    // remuneration
    remunerationCompetitive: 'remuneration.competitive',
    remunerationMin: 'remuneration.min',
    remunerationCurrency: 'remuneration.currency',
    remunerationTimePeriod: 'remuneration.time-period',
    // Admin-specific
    companyPackages: 'company.packages',
    manager: 'manager',
    published: 'published',
    // UI-specific pagination
    page: 'page',
} as const

const customDecoders = {
    roleType: (value?: string): RoleType | undefined => {
        if (
            value === AllRoleTypes.FullTime ||
            value === AllRoleTypes.Contract ||
            value === AllRoleTypes.Intern
        ) {
            return value
        }
        return undefined
    },
    remunerationTimePeriod: (value?: string) => {
        if (
            value === AllRemunerationTimePeriods.Yearly ||
            value === AllRemunerationTimePeriods.Daily
        ) {
            return value
        }
        return undefined
    },
    companyPackages: (value?: string): string | undefined => {
        if (
            value === Packages.Explore.gqlName ||
            value === Packages.LaunchPad.gqlName ||
            value === Packages.TakeOff.gqlName
        ) {
            return value
        }
        return undefined
    },
}

export type ParamsWithDefaults = Omit<Params, 'page'> & { page: number }

export const applyDefaults = (params: Params) => {
    if (params.page === undefined) {
        // NB: If it's not the `page` that's changed, reset the `page=1`.
        //     This should result in clearer behavioral analytics, since
        //     users will be forced to click 'Show more jobs' more often.
        params.page = 1
    }
    return params as ParamsWithDefaults
}

export const queryToParams = (query: ParsedUrlQuery) => {
    // NB: The Router's `query` comes parsed but its props are still URL-encoded.

    return applyDefaults(
        pickBy<Params, Params>(notNullOrUndefinedOrEmpty, {
            search: decodeArray(query.search, decoders.string),
            remote: decodeValue(query[queryParamName.remote], decoders.boolean),
            sponsorshipOffered: decodeValue(
                query[queryParamName.sponsorshipOffered],
                decoders.boolean,
            ),
            tags: decodeArray(query[queryParamName.tags], decoders.string),
            roleTypes: decodeArray(
                query[queryParamName.roleTypes],
                customDecoders.roleType,
            ),
            // location
            locationCity: decodeArray(
                query[queryParamName.locationCity],
                decoders.string,
            ),
            locationCountryCode: decodeArray(
                query[queryParamName.locationCountryCode],
                decoders.string,
            ),
            locationRegion: decodeArray(
                query[queryParamName.locationRegion],
                decoders.string,
            ),
            // remuneration
            remunerationCompetitive: decodeValue(
                query[queryParamName.remunerationCompetitive],
                decoders.boolean,
            ),
            remunerationMin: decodeValue(
                query[queryParamName.remunerationMin],
                decoders.posInt,
            ),
            remunerationCurrency: decodeValue(
                query[queryParamName.remunerationCurrency],
                decoders.string,
            ),
            remunerationTimePeriod: decodeValue(
                query[queryParamName.remunerationTimePeriod],
                customDecoders.remunerationTimePeriod,
            ),
            // Admin-specific
            companyPackages: decodeArray(
                query[queryParamName.companyPackages],
                customDecoders.companyPackages,
            ),
            manager: decodeValue(query[queryParamName.manager], decoders.string),
            published: decodeValue(query[queryParamName.published], decoders.boolean),
            // UI-specific pagination
            page: decodeValue(query[queryParamName.page], decoders.posInt),
        }),
    )
}

export const prepareParams = (params: Params) => {
    const query: Record<string, any> = {
        [queryParamName.remote]: params.remote,
        [queryParamName.sponsorshipOffered]: params.sponsorshipOffered,
        [queryParamName.tags]: params.tags,
        [queryParamName.roleTypes]: params.roleTypes,
        // location
        [queryParamName.locationRegion]: params.locationRegion,
        [queryParamName.locationCity]: params.locationCity,
        [queryParamName.locationCountryCode]: params.locationCountryCode,
        // remuneration
        [queryParamName.remunerationCompetitive]: params.remunerationCompetitive,
        [queryParamName.remunerationMin]: params.remunerationMin,
        [queryParamName.remunerationCurrency]: params.remunerationCurrency,
        [queryParamName.remunerationTimePeriod]: params.remunerationTimePeriod,
        // Admin-specific
        [queryParamName.companyPackages]: params.companyPackages,
        [queryParamName.manager]: params.manager,
        [queryParamName.published]: params.published,
    }
    if (params.page && params.page > 1) {
        // UI-specific pagination
        query[queryParamName.page] = params.page
    }

    return {
        searchTerms: params.search,
        query: pickBy<Params, Record<string, any>>(notNullOrUndefinedOrEmpty, query),
    }
}

export const createURL = (params: Params) => {
    return routes.jobsboard(prepareParams(params))
}

export const useParams = (initValue?: Params) => {
    const router = useRouter()
    const { query } = router

    const params = useMemo((): ParamsWithDefaults => {
        return initValue === undefined || isEmpty(initValue)
            ? queryToParams(query)
            : applyDefaults(initValue)
    }, [query, initValue])

    const updateParams = useCallback(
        (params: Params) => {
            if (isServerSide) {
                console.error(
                    'The `updateParams` called during SSR which must not happen!',
                )
            } else {
                router.push(createURL(params), undefined, { scroll: false })
            }
        },
        [router],
    )

    return {
        params,
        updateParams,
    }
}
