import useQuery from 'hooks/useQuery'
import useVerticalData from 'hooks/useVerticalData'
import { QUERY_ASSOCIATED_TAGS } from 'containers/admin/vertical-tags-association/queries'
import * as seoJobsboard from 'containers/jobs-board/components/seo/seoJobsboard.lib'
import { PresetSearch } from 'containers/jobs-board/context/JobsSearchContext'
import { Seo } from 'components/seo/Seo'

export const SeoJobsBoard = ({
    presetSearch,
}: {
    presetSearch: PresetSearch | undefined
}) => {
    const verticalData = useVerticalData()
    const { vertical, verticalConfig } = verticalData

    const title = seoJobsboard.title(verticalConfig, presetSearch)
    const description = seoJobsboard.description(verticalConfig)
    const keywords = seoJobsboard.keywords(vertical, verticalConfig, presetSearch)

    const [{ data }] = useQuery({
        query: QUERY_ASSOCIATED_TAGS,
        variables: { vertical: vertical },
        pause: !vertical,
    })
    const associatedTags = data?.associated_tags?.results

    const noIndexAndNoFollow = seoJobsboard.shouldNoIndexAndNoFollow(
        vertical,
        associatedTags,
        presetSearch,
    )

    return (
        <Seo
            title={title}
            description={description}
            keywords={keywords}
            noindex={noIndexAndNoFollow}
            nofollow={noIndexAndNoFollow}
        />
    )
}
