import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { equals, merge, pickBy } from 'ramda'
import { notNullOrUndefinedOrEmpty } from 'lib/predicates'
import { useAnalytics } from 'hooks/useAnalytics'
import {
    applyDefaults,
    Params,
    SearchParams,
    useParams,
} from 'containers/jobs-board/hooks/useParams'

export type PresetSearch = {
    urlFragment: string
    title?: string
    searchParams: SearchParams
}

type InternalState = {
    filters: Params
    presetSearch?: PresetSearch
    ignoreParamsUpdate?: boolean
}

const JobsSearchContext = createContext<
    | (InternalState & {
          updateFilters: (params: Params) => void
          resetFilters: () => void
      })
    | null
>(null)

const prepareFilters = (newFilters: Params, prevFilters: Params = {}) => {
    return pickBy<Params, Params>(
        notNullOrUndefinedOrEmpty,
        merge(prevFilters, applyDefaults(newFilters)),
    )
}

export const JobsSearchContextProvider = ({
    presetSearch,
    children,
}: {
    presetSearch?: PresetSearch
    children: ReactNode
}) => {
    const { params, updateParams } = useParams(presetSearch?.searchParams)

    const [internalState, setInternalState] = useState<InternalState>({
        filters: params,
        presetSearch,
    })

    const updateFilters = (params: Params) => {
        setInternalState(prevState => {
            return {
                filters: prepareFilters(params, prevState.filters),
                ignoreParamsUpdate: true,
                // intentionally drop the `presetSearch`, if any
            }
        })
    }
    const resetFilters = () => {
        setInternalState(_prevState => {
            return {
                filters: prepareFilters({}),
                ignoreParamsUpdate: true,
                // intentionally drop the `presetSearch`, if any
            }
        })
    }

    // IMPORTANT: Whenever you decide to change this logic, test
    // the following state changing flows on the Jobsboard page:
    // 1. <init state>  -> preset search
    // 2. preset search -> manually updated filters
    // 3. preset search -> another preset search
    // 4. <any state>   -> manually cleared search filters
    // 5. browser navigation for all aforementioned scenarios
    const analytics = useAnalytics()
    useEffect(() => {
        // NB: It is implemented as an effect for a reason — we
        //     need this part decoupled from the `updateFilters`
        //     logic because they may change as a result of the
        //     `params` update (see another effect below). Also,
        //     do not update `params` upon an initial page load.
        const newFilters = internalState.filters
        if (!equals(params, newFilters)) {
            analytics?.track('Jobs search filters applied', {
                filters: newFilters,
            })
            updateParams(newFilters)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalState.filters])
    useEffect(() => {
        // NB: This effect is crucial for browser navigation to work.
        //     Still, we don't need to change an internal state when
        //     it was triggered by a manual filters update.
        setInternalState(prevState => {
            const {
                filters: prevFilters,
                presetSearch: prevPresetSearch,
                ignoreParamsUpdate,
            } = prevState

            let updFilters = prevFilters
            if (!ignoreParamsUpdate && !equals(prevFilters, params)) {
                updFilters = prepareFilters(params) // drop previous
            }
            let updPresetSearch = prevPresetSearch
            if (!ignoreParamsUpdate && !equals(prevPresetSearch, presetSearch)) {
                analytics?.track('Preset search link/tag clicked', {
                    filters: presetSearch?.searchParams,
                })
                updPresetSearch = presetSearch
            }
            return {
                filters: updFilters,
                presetSearch: updPresetSearch,
                // intentionally drop the `ignoreParamsUpdate`, if any
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    return (
        <JobsSearchContext.Provider
            value={{ ...internalState, updateFilters, resetFilters }}
        >
            {children}
        </JobsSearchContext.Provider>
    )
}

export const useJobsSearchContext = () => {
    const context = useContext(JobsSearchContext)
    if (!context) {
        throw new Error('Please use JobsSearchContext.Provider')
    }
    return context
}
