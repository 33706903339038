import { OptionBase } from 'chakra-react-select'
import { withSymbol } from 'entities/currency'
import { RoleType, RemunerationTimePeriod } from 'entities/job'
import { Packages } from 'entities/packages'
import { formatAmount } from 'lib/number'
import { queryParamName } from 'containers/jobs-board/hooks/useParams'

export type Option = {
    label: string
    value: string
    __isNew__?: boolean // backed into 'react-select' options
} & OptionBase

export type SearchOptionType = 'Preset' | 'Param'

export type SearchOption = Option & {
    __isNew__: true // required for more than one search term
    type: SearchOptionType
}
type TagOption = Option & { type: typeof queryParamName.tags }
type RemoteOption = Option & { type: typeof queryParamName.remote }
type RelocationOption = Option & { type: typeof queryParamName.sponsorshipOffered }
type LocationCityOption = Option & { type: typeof queryParamName.locationCity }
type LocationRegionOption = Option & { type: typeof queryParamName.locationRegion }
type LocationCountryCodeOption = Option & {
    type: typeof queryParamName.locationCountryCode
}
type RoleTypeOption = Option & {
    value: RoleType
    label: RoleType
    type: typeof queryParamName.roleTypes
}
type RemunerationMinOption = Omit<Option, 'value'> & {
    type: typeof queryParamName.remunerationMin
    value: number
}
export type RemunerationCurrencyOption = Option & {
    type: typeof queryParamName.remunerationCurrency
}
type RemunerationTimePeriodOption = Option & {
    value: RemunerationTimePeriod
    label: RemunerationTimePeriod
    type: typeof queryParamName.remunerationTimePeriod
}

// Admin-specific
type CompanyPackagesOption = Option & { type: typeof queryParamName.companyPackages }
type ManagerOption = Option & { type: typeof queryParamName.manager }
type PublishedOption = Option & { type: typeof queryParamName.published }

//

export type SelectOption =
    | SearchOption
    | TagOption
    | LocationCityOption
    | LocationRegionOption
    | LocationCountryCodeOption
    | RemoteOption
    | RelocationOption
    | RoleTypeOption
    | RemunerationMinOption
    | RemunerationCurrencyOption
    | RemunerationTimePeriodOption
    | CompanyPackagesOption
    | ManagerOption
    | PublishedOption

//

export const isSearchOption = (option: SelectOption): option is SearchOption =>
    option.__isNew__ === true
export const isTagOption = (option: SelectOption): option is TagOption =>
    option.type === queryParamName.tags
export const isRoleTypeOption = (
    option: SelectOption,
): option is RoleTypeOption => option.type === queryParamName.roleTypes
export const isRemoteOption = (option: SelectOption): option is RemoteOption =>
    option.type === queryParamName.remote
export const isRelocationOption = (option: SelectOption): option is SelectOption =>
    option.type === queryParamName.sponsorshipOffered
export const isLocationCityOption = (
    option: SelectOption,
): option is LocationCityOption => option.type === queryParamName.locationCity
export const isLocationRegionOption = (
    option: SelectOption,
): option is LocationRegionOption => option.type === queryParamName.locationRegion
export const isLocationCountryCodeOption = (
    option: SelectOption,
): option is LocationCountryCodeOption =>
    option.type === queryParamName.locationCountryCode
export const isRemunerationMinOption = (
    option: SelectOption,
): option is RemunerationMinOption => option.type === queryParamName.remunerationMin
export const isRemunerationCurrencyOption = (
    option: SelectOption,
): option is RemunerationCurrencyOption =>
    option.type === queryParamName.remunerationCurrency
export const isRemunerationTimePeriodOption = (
    option: SelectOption,
): option is RemunerationTimePeriodOption =>
    option.type === queryParamName.remunerationTimePeriod
export const isCompanyPackagesOption = (
    option: SelectOption,
): option is CompanyPackagesOption => option.type === queryParamName.companyPackages
export const isManagerOption = (option: SelectOption): option is ManagerOption =>
    option.type === queryParamName.manager
export const isPublishedOption = (option: SelectOption): option is PublishedOption =>
    option.type === queryParamName.published

//

export const createSearchOption = (
    item: string,
    type: SearchOptionType,
): SearchOption => ({
    label: type === 'Preset' ? item : '"' + item + '"', // the quotes are to visually differentiate search terms
    value: item,
    __isNew__: true,
    type: type,
})
export const createTagOption = (item: string): TagOption => ({
    label: item,
    value: item,
    type: queryParamName.tags,
})
export const createRemoteOption = (): RemoteOption => ({
    label: 'Remote',
    // can't put 'true' to mitigate duplication, we use .value as a key in react-select
    value: 'remote-true',
    type: queryParamName.remote,
})

export const createRelocationOption = (): RelocationOption => ({
    label: 'Relocation',
    // can't put 'true' to mitigate duplication, we use .value as a key in react-select
    value: 'relocation-true',
    type: queryParamName.sponsorshipOffered,
})

export const createLocationCityOption = (item: string): LocationCityOption => ({
    label: item,
    value: item,
    type: queryParamName.locationCity,
})
export const createLocationRegionOption = (item: string): LocationRegionOption => ({
    label: item,
    value: item,
    type: queryParamName.locationRegion,
})

export const createLocationCountryCodeOption = (
    countryName: string,
    countryCode: string,
): LocationCountryCodeOption => {
    return {
        label: countryName,
        value: countryCode,
        type: queryParamName.locationCountryCode,
    }
}
export const createRoleTypeOption = (item: RoleType): RoleTypeOption => ({
    label: item,
    value: item,
    type: queryParamName.roleTypes,
})

export const createRemunerationMinOption = (item: number): RemunerationMinOption => ({
    label: `From: ${formatAmount(item, true, true)}`,
    value: item,
    type: queryParamName.remunerationMin,
})
export const createRemunerationCurrencyOption = (
    item: string,
): RemunerationCurrencyOption => ({
    label: `${withSymbol(item)}`,
    value: item,
    type: queryParamName.remunerationCurrency,
})
export const createRemunerationTimePeriodOption = (
    item: RemunerationTimePeriod,
): RemunerationTimePeriodOption => ({
    label: item,
    value: item,
    type: queryParamName.remunerationTimePeriod,
})

// Admin-specific
export const createCompanyPackagesOption = (gqlName: string): CompanyPackagesOption => ({
    label: `${Object.values(Packages).find(p => p.gqlName === gqlName)?.name}`,
    value: gqlName,
    type: queryParamName.companyPackages,
})
export const createManagerOption = (): ManagerOption => ({
    label: 'My jobs',
    // can't put 'true' to mitigate duplication, we use .value as a key in react-select
    value: 'my-jobs-true',
    type: queryParamName.manager,
})
export const createPublishedOption = (): PublishedOption => ({
    label: 'Published',
    // can't put 'true' to mitigate duplication, we use .value as a key in react-select
    value: 'published-true',
    type: queryParamName.published,
})

//

export const facetsForUI = {
    affiliateScore: 'affiliate-score',
} as const

export type Facet = {
    attr:
        | typeof queryParamName.locationCity
        | typeof queryParamName.locationCountryCode
        | typeof queryParamName.locationRegion
        | typeof facetsForUI.affiliateScore
        | (string & {})
    value: string
    count: number
}

export type CityInfo =
    | {
          city: string
          country: string
          countryCode: string
          region: string
      }
    | {
          city: null
          country: string
          countryCode: string
          region: string
      }

export type CountryCodeToCountryName = { [countryCode in string]: string }

export const cityInfoToCountryCodeToCountryName = (
    cityInfo?: CityInfo[],
): CountryCodeToCountryName => {
    const countryCodeToCountryName = {} as CountryCodeToCountryName
    cityInfo?.forEach(city => {
        countryCodeToCountryName[city.countryCode] = city.country
    })
    return countryCodeToCountryName
}

export type RemunerationRange = {
    currency: string
    min: number
    max: number
    timePeriod: RemunerationTimePeriod
}
