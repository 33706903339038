import React from 'react'
import Head from 'next/head'
import { routes } from 'routes'
import { useVertical, useVerticalConfig, useVerticalUrl } from 'hooks/useVerticalData'

export const AboutWorkshub = () => {
    const url = useVerticalUrl()
    const vertical = useVertical()
    const verticalConfig = useVerticalConfig()

    const companyData = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: verticalConfig.platformName,
        sameAs: verticalConfig.twitter,
        url,
        logo: url + routes.resource.logo(vertical),
    }

    return (
        <Head>
            <script
                id={'company-data'}
                type={'application/ld+json'}
                dangerouslySetInnerHTML={{ __html: JSON.stringify(companyData) }}
            />
        </Head>
    )
}
