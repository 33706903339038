import React from 'react'
import algoliasearch from 'algoliasearch/lite'
import { getEnvGlobal } from 'config'
import { isProduction } from 'lib/env'

const searchClient = algoliasearch(
    getEnvGlobal('algoliaAppId'),
    getEnvGlobal('algoliaSearchApiKey'),
)

export type IndexID = 'tags' | 'users'

const getIndexName = (indexId: IndexID) => {
    return `${isProduction() ? '' : 'dev_'}${indexId}`
}

// NB: To be used in `getInitialProps`. Use a hook version within components.
export const getAlgoliaIndex = (indexId: IndexID) => {
    return searchClient.initIndex(getIndexName(indexId))
}

export const useAlgoliaIndex = (indexId: IndexID) => {
    return React.useMemo(() => getAlgoliaIndex(indexId), [indexId])
}
