import {
    chakra,
    Flex,
    Heading,
    Icon,
    LinkBox,
    LinkOverlay,
    Skeleton,
    Text,
    useTheme,
} from '@chakra-ui/react'
import Link from 'next/link'
import { Users } from 'emotion-icons/feather'
import { CompanySizes } from 'entities/company'
import { candidateHasMatch, companyHasMatch, formatJobLocationInfo } from 'entities/job'
import { job as mockJob } from 'entities/job.mock'
import { skeletonLocation } from 'entities/location.mock'
import { routes } from 'routes'
import useMe from 'hooks/useMe'
import { CardWrapper } from 'components/CardWrapper'
import { Flair, FlairProps } from 'components/flair/Flair'
import { Avatar } from 'components/images/Avatar'
import {
    JobMiniCardData,
    JobMiniCardFooter,
    JobMiniCardRemote,
    JobMiniCardRemuneration,
    JobMiniCardRoleType,
    JobMiniCardTags,
} from 'components/job/JobMiniCard'
import { JobCardData } from 'components/job/queries'
import StarIcon from 'public/img/star2_icon.svg'

const UsersIcon = chakra(Users)

const JobCardMetaInfo = ({ job }: { job?: JobCardData }) => {
    const {
        company: { size = mockJob.company.size } = mockJob.company ?? {},
        remuneration = mockJob.remuneration ?? {},
        roleType = mockJob.roleType,
        remote = mockJob.remote,
    } = job ?? {}

    const isLoaded = Boolean(job?.id)

    return (
        <Skeleton isLoaded={isLoaded} w="fit-content" mb="36px">
            <Flex flexWrap="wrap" gridColumnGap="16px" gridRowGap="10px">
                <JobMiniCardRemuneration
                    remuneration={remuneration}
                    variant="functionalSemiBold"
                />
                <JobMiniCardRoleType roleType={roleType} />
                {size && (
                    <Flex gridGap="6px" alignItems="center">
                        <UsersIcon flexShrink={0} boxSize="19px" strokeWidth={1.5} />

                        <Skeleton isLoaded={isLoaded}>
                            <Text variant="functionalNormal" noOfLines={1}>
                                {CompanySizes[size]}
                            </Text>
                        </Skeleton>
                    </Flex>
                )}
                <JobMiniCardRemote remote={remote} />
            </Flex>
        </Skeleton>
    )
}

const JobCardFlair = ({ children, ...restProps }: FlairProps) => {
    return (
        <Flair
            size="sm"
            position="absolute"
            right="6px"
            top="6px"
            p="3px 4px"
            {...restProps}
        >
            {children}
        </Flair>
    )
}

export const JobCard = ({ job }: { job?: JobCardData }) => {
    const me = useMe()
    const theme = useTheme()
    const matchFlairBgGradient = theme.gradients.match_flair_background

    const {
        slug = mockJob.slug,
        title = mockJob.title,
        company: { logo: companyLogo = mockJob.company.logo } = mockJob.company ?? {},
        tags = mockJob.tags,
        tagline = mockJob.tagline,
    } = job ?? {}

    const isLoaded = Boolean(job?.id)
    const jobURL = isLoaded ? routes.job({ slug }) : '#'

    return (
        <LinkBox
            as={CardWrapper}
            size="full"
            p="26px"
            alignContent="space-between"
            gridGap="16px"
            position="relative"
            _hover={{
                boxShadow: 'dropdown',
            }}
            transition="box-shadow 0.3s"
        >
            <Flex gridGap="17px" align="center">
                {companyLogo && (
                    <Avatar
                        variant="company"
                        src={companyLogo}
                        boxSize="48px"
                        isLoaded={isLoaded}
                    />
                )}

                <Flex alignSelf="center" direction="column" gridGap="1px">
                    <Skeleton isLoaded={isLoaded}>
                        <Link href={jobURL} passHref>
                            <LinkOverlay w="fit-content">
                                <Heading
                                    as="h3"
                                    size="sm"
                                    letterSpacing={0}
                                    noOfLines={1}
                                >
                                    {title}
                                </Heading>
                            </LinkOverlay>
                        </Link>
                    </Skeleton>
                    <Skeleton isLoaded={isLoaded}>
                        <Text
                            variant="functionalButtonsSmall"
                            fontWeight={500}
                            // NB: For "New York, United States of America",
                            //     on `global-min=320px`, it otherwise stretches the UI.
                            overflowWrap="anywhere"
                            noOfLines={1}
                        >
                            {job?.company.name},{' '}
                            {(job && formatJobLocationInfo(job, 'Remote')) ||
                                skeletonLocation.country}
                        </Text>
                    </Skeleton>
                </Flex>
            </Flex>
            {me?.isCandidate && candidateHasMatch(job) && (
                <JobCardFlair
                    icon={<Icon as={StarIcon} boxSize="16px" />}
                    color="white"
                    backgroundImage={matchFlairBgGradient}
                    backgroundColor="primary.500"
                >
                    Match
                </JobCardFlair>
            )}
            {me?.isCompanyUser && companyHasMatch(job) && (
                <JobCardFlair
                    icon={<Icon as={StarIcon} boxSize="16px" />}
                    color="white"
                    backgroundImage={matchFlairBgGradient}
                    backgroundColor="primary.500"
                >
                    {`${job?.matchingUsers ?? 0} match(es)`}
                </JobCardFlair>
            )}
            {!me?.isCandidate && job && !job.published && (
                <JobCardFlair
                    color="yellow_wh.200"
                    backgroundColor="semantic.alert.bg_light"
                >
                    Unpublished
                </JobCardFlair>
            )}
            {tagline && (
                <Skeleton isLoaded={isLoaded} w="fit-content" mb="2px">
                    <Text
                        variant="functionalButtonsSmall"
                        fontWeight={500}
                        color="text.secondary"
                        noOfLines={2}
                    >
                        {tagline}
                    </Text>
                </Skeleton>
            )}
            <JobMiniCardTags tags={tags} isLoaded={isLoaded} />
            <JobCardMetaInfo job={job} />
            <JobMiniCardFooter isLoaded={isLoaded} me={me} job={job as JobMiniCardData} />
        </LinkBox>
    )
}
