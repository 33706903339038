import { useEffect, useState } from 'react'
import { Checkbox, Flex } from '@chakra-ui/react'
import { Packages } from 'entities/packages'
import { LoggedInUser } from 'entities/user'
import { append } from 'lib/array'
import useMe from 'hooks/useMe'
import { FilterInput } from 'containers/jobs-board/components/FilterInput'
import { ToggleInput } from 'containers/jobs-board/components/ToggleInput'
import { useJobsSearchContext } from 'containers/jobs-board/context/JobsSearchContext'

export const CompanyPackageFilter = () => {
    const { filters, updateFilters } = useJobsSearchContext()

    const [selectedCompanyPackages, setSelectedCompanyPackages] = useState<string[]>(
        filters.companyPackages ?? [],
    )
    useEffect(() => {
        setSelectedCompanyPackages(filters.companyPackages ?? [])
    }, [filters.companyPackages])

    const handleOnChange = (value: string) => {
        if (selectedCompanyPackages?.includes(value)) {
            const companyPackages = selectedCompanyPackages.filter(p => p !== value)
            updateFilters({
                companyPackages:
                    companyPackages.length === 0 ? undefined : companyPackages,
            })
        } else {
            updateFilters({ companyPackages: append(selectedCompanyPackages, value) })
        }
    }

    return (
        <FilterInput label="Company package" minWidth="200px">
            <Flex direction="column" gridGap="24px">
                {Object.values(Packages).map(p => {
                    const companyPackage = p.gqlName
                    return (
                        <Checkbox
                            key={companyPackage}
                            isChecked={selectedCompanyPackages?.includes(companyPackage)}
                            onChange={() => handleOnChange(companyPackage)}
                        >
                            {p.name}
                        </Checkbox>
                    )
                })}
            </Flex>
        </FilterInput>
    )
}

export const OnlyMyJobsFilter = ({ me }: { me: LoggedInUser }) => {
    const { filters, updateFilters } = useJobsSearchContext()

    const handleOnlyMyJobsToggle = () => {
        updateFilters({
            manager: filters.manager ? undefined : me.email,
        })
    }

    return (
        <ToggleInput
            labelText="Only my jobs"
            onToggle={handleOnlyMyJobsToggle}
            isChecked={!!filters.manager}
        />
    )
}

export const OnlyPublishedFilter = () => {
    const { filters, updateFilters } = useJobsSearchContext()

    const handleOnlyPublishedToggle = () => {
        updateFilters({
            published: filters.published ? undefined : true,
        })
    }

    return (
        <ToggleInput
            labelText="Only published"
            onToggle={handleOnlyPublishedToggle}
            isChecked={filters.published ?? false}
        />
    )
}

const AdminFilters = () => {
    const me = useMe()

    if (!me?.isAdmin) {
        return null
    }

    return (
        <Flex
            mt="24px"
            px={{ base: '12px', md: '24px', lg: '36px' }}
            sx={{
                '::-webkit-scrollbar': {
                    display: 'none',
                    scrollbarWidth: 'none',
                },
            }}
            overflowX="auto"
        >
            <Flex gridGap="8px" mr="auto">
                <CompanyPackageFilter />
                <OnlyMyJobsFilter me={me} />
                <OnlyPublishedFilter />
            </Flex>
        </Flex>
    )
}
export default AdminFilters
