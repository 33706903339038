import { format } from 'date-fns'
import { countryCodeToCountryName } from 'entities/country'
import { VerticalAssociationTag } from 'entities/tag'
import { companyVertical, StandardVertical } from 'entities/vertical'
import { VerticalConfig } from 'lib/vertical'
import { PresetSearch } from 'containers/jobs-board/context/JobsSearchContext'

export const title = (
    verticalConfig: VerticalConfig,
    presetSearch: PresetSearch | undefined,
) => {
    if (presetSearch) {
        return presetSearch.title
    } else {
        return `Jobs - ${format(new Date(), 'MMMM yyyy')} | ${
            verticalConfig.platformName
        }`
    }
}

//

export const description = (verticalConfig: VerticalConfig) => {
    return verticalConfig.description
}

//

const softwareRelatedWords = [
    'Software Developer',
    'Software Engineering',
    'Developer',
    'Programming',
]

function isRemoteOffering(
    vertical: StandardVertical,
    presetSearch: PresetSearch | undefined,
) {
    return vertical === 'remote' || presetSearch?.searchParams.remote
}

function getRemoteKeywords(
    keywords: string[],
    vertical: StandardVertical,
    presetSearch: PresetSearch | undefined,
) {
    return isRemoteOffering(vertical, presetSearch)
        ? keywords.map(kwd => (kwd.startsWith('Remote') ? kwd : `Remote ${kwd}`))
        : []
}

function getTagLabel(presetSearch: PresetSearch | undefined) {
    // NB: A preset search always has a single tech tag or has no tags at all.
    return presetSearch?.searchParams.tags
        ? presetSearch?.searchParams.tags[0]
        : undefined
}

function getExtraTechKeywords(
    verticalConfig: VerticalConfig,
    presetSearch: PresetSearch | undefined,
) {
    // NB: Since we don't index the `companyVertical` ('www'), it's always tech.
    const techTerm = getTagLabel(presetSearch) ?? verticalConfig.labelName
    return softwareRelatedWords.map(srw => `${techTerm} ${srw} jobs`)
}

function getLocation(presetSearch: PresetSearch | undefined) {
    if (presetSearch?.searchParams.locationCity) {
        return presetSearch?.searchParams.locationCity[0]
    }
    if (presetSearch?.searchParams.locationCountryCode) {
        const countryCode = presetSearch?.searchParams.locationCountryCode[0]
        return countryCodeToCountryName[countryCode]
    }
}

function getExtraLocationKeywords(
    vertical: StandardVertical,
    verticalConfig: VerticalConfig,
    presetSearch: PresetSearch | undefined,
) {
    const res: string[] = []
    if (vertical !== companyVertical && vertical !== 'remote') {
        const verticalLabel = verticalConfig.labelName
        const location = getLocation(presetSearch)
        res.push(
            location ? `${verticalLabel} jobs in ${location}` : `${verticalLabel} jobs`,
        )
    }
    return res
}

export const keywords = (
    vertical: StandardVertical,
    verticalConfig: VerticalConfig,
    presetSearch: PresetSearch | undefined,
): string[] => {
    const presetSearchKeywords = [
        getTagLabel(presetSearch),
        getLocation(presetSearch),
    ].filter(Boolean) as string[]

    const baseKeywords = [...softwareRelatedWords, ...presetSearchKeywords]
        .sort()
        .map(s => `${s} jobs`)

    const extraTechKeywords = getExtraTechKeywords(verticalConfig, presetSearch)
    const extraLocationKeywords = getExtraLocationKeywords(
        vertical,
        verticalConfig,
        presetSearch,
    )
    const nonRemoteKeywords = [
        ...baseKeywords,
        ...extraTechKeywords,
        ...extraLocationKeywords,
    ]
    const remoteKeywords = getRemoteKeywords(nonRemoteKeywords, vertical, presetSearch)

    return [...nonRemoteKeywords, ...remoteKeywords]
}

//

function isAssociatedTag(
    techLabel: string,
    associatedTags: VerticalAssociationTag[] | undefined,
) {
    return !!associatedTags?.find(vat => vat.label === techLabel)
}

export const shouldNoIndexAndNoFollow = (
    vertical: StandardVertical,
    associatedTags: VerticalAssociationTag[] | undefined,
    presetSearch: PresetSearch | undefined,
) => {
    if (vertical === companyVertical) {
        return true
    }

    const techLabel = getTagLabel(presetSearch)
    return techLabel ? !isAssociatedTag(techLabel, associatedTags) : true
}
