import React, { useMemo } from 'react'
import { Box, Button, Text, useToken } from '@chakra-ui/react'
import Link from 'next/link'
import { merge, range } from 'ramda'
import { gql, useQuery } from 'urql'
import { Job } from 'entities/job'
import { Me } from 'entities/user'
import { StandardVertical } from 'entities/vertical'
import { formatNumber } from 'lib/number'
import { useAnalytics } from 'hooks/useAnalytics'
import { WithLikedJobs } from 'hooks/useLikedJobs'
import useMe from 'hooks/useMe'
import { useVertical } from 'hooks/useVerticalData'
import AdminFilters from 'containers/jobs-board/components/AdminFilters'
import { JobsBoardHeader } from 'containers/jobs-board/components/JobsBoardHeader'
import {
    COLUMNS_AT_MEDIUM_SCREEN_SIZE,
    JobsGrid,
} from 'containers/jobs-board/components/JobsGrid'
import { CityInfo, Facet, facetsForUI } from 'containers/jobs-board/components/options'
import { SeoJobsBoard } from 'containers/jobs-board/components/seo/SeoJobsBoard'
import {
    PresetSearch,
    useJobsSearchContext,
} from 'containers/jobs-board/context/JobsSearchContext'
import {
    createURL,
    Params,
    ParamsWithDefaults,
    useParams,
} from 'containers/jobs-board/hooks/useParams'
import { CompanyPromoBanner } from 'components/CompanyPromoBanner'
import AppShell from 'components/appShell'
import { FRAGMENT_JOB_CARD, JobCardData } from 'components/job/queries'

const CURATED_AND_PARTNER_PAGE_SIZE = 12

const contentSections = {
    curated: {
        // NB: We decided to use the previous title, not the '🏄 Fast apply jobs'.
        title: '🏄 Curated jobs',
        pageSize: CURATED_AND_PARTNER_PAGE_SIZE,
    },
    partner: {
        title: '🔍 Jobs from around the Web',
        pageSize: CURATED_AND_PARTNER_PAGE_SIZE,
    },
    recommended: {
        title: 'Other jobs based on your skills',
        pageSize: 4,
    },
    latest: {
        title: 'Other jobs you might like',
        pageSize: 4,
    },
} as const

type JobsNumber = {
    total: number
    curated: number
    partner: number
}

const getJobsNumber = (facets: Facet[] | undefined): JobsNumber => {
    let curated = 0,
        partner = 0
    facets?.filter(facet => {
        if (facet.attr === facetsForUI.affiliateScore) {
            if (facet.value === '0') {
                curated = facet.count
            } else {
                partner = facet.count
            }
        }
    })
    return {
        total: curated + partner,
        curated,
        partner,
    }
}

type SearchFilters = {
    location?: {
        cities: Params['locationCity']
        countryCodes: Params['locationCountryCode']
        regions: Params['locationRegion']
    }
    tags?: Params['tags']
    roleTypes?: Params['roleTypes']
    company?: {
        packages?: Params['companyPackages']
    }
} & Partial<
    Pick<Job, 'manager' | 'published' | 'remote' | 'remuneration' | 'sponsorshipOffered'>
>

const QUERY_RECOMMENDED_JOBS = gql<
    { jobs: JobCardData[] },
    {
        id: string
        pageSizeJobs: number
    }
>`
    ${FRAGMENT_JOB_CARD}
    query recommended_jobs(
        $id: ID
        $vertical: vertical
        $pageSizeJobs: Int
        $pageSizeBlogs: Int
    ) {
        jobs(
            filter_type: "recommended"
            entity_type: "user"
            entity_id: $id
            page_size: $pageSizeJobs
            page_number: 1
        ) {
            ...JobCard
        }
    }
`

const QUERY_SEARCH_JOBS = gql<
    {
        jobs_search: {
            jobs: JobCardData[]
            page: number
            numberOfPages: number
            numberOfHits: number
            facets: Facet[]
        }
        city_info: CityInfo[]
    },
    {
        vertical?: StandardVertical
        search_term?: string
        filters?: SearchFilters
        preset_search?: string
        page?: number
        page_size?: number
        offset?: number
        length?: number
    }
>`
    ${FRAGMENT_JOB_CARD}
    query jobs_search(
        $vertical: vertical
        $search_term: String
        $filters: SearchFiltersInput
        $preset_search: String
        $page: Int
        $page_size: Int
        $offset: Int
        $length: Int
    ) {
        jobs_search(
            vertical: $vertical
            search_term: $search_term
            filters: $filters
            preset_search: $preset_search
            page: $page
            page_size: $page_size
            offset: $offset
            length: $length
        ) {
            numberOfPages
            numberOfHits
            page
            jobs {
                ...JobCard
            }
            facets {
                attr
                value
                count
            }
        }
        city_info {
            city
            country
            countryCode
            region
        }
    }
`

const NoMatchingJob = () => {
    return (
        <Box
            justifyContent={{ sm: 'center' }}
            alignItems={{ sm: 'center' }}
            py={{ base: '60px', sm: '120px' }}
        >
            <Text
                variant={{ base: 'bodyTextMedium', sm: 'bodyTextLarge' }}
                textAlign={{ base: 'left', sm: 'center' }}
                size="custom"
            >
                There’re no matching jobs yet 🧩
            </Text>
        </Box>
    )
}

const RecommendedJobs = ({ me }: { me: Me }) => {
    const [{ data, fetching, error }] = useQuery({
        query: QUERY_RECOMMENDED_JOBS,
        variables: {
            id: me?.id as string,
            pageSizeJobs: contentSections.recommended.pageSize,
        },
        pause: !me?.id,
    })

    if (error) {
        return null
    }

    return (
        <JobsGrid
            title={contentSections.recommended.title}
            jobs={data?.jobs}
            loading={fetching}
        />
    )
}

const LatestJobs = ({ vertical }: { vertical: StandardVertical }) => {
    const [{ data, fetching, error }] = useQuery({
        query: QUERY_SEARCH_JOBS,
        variables: {
            page: 1,
            page_size: contentSections.latest.pageSize,
            vertical,
        },
    })

    if (error) {
        return null
    }

    return (
        <JobsGrid
            title={contentSections.latest.title}
            jobs={data?.jobs_search?.jobs}
            loading={fetching}
        />
    )
}

const OtherJobs = () => {
    const vertical = useVertical()
    const me = useMe()

    const hasRecommendations = me?.isCandidate

    return hasRecommendations ? (
        <RecommendedJobs me={me} />
    ) : (
        <LatestJobs vertical={vertical} />
    )
}

const ShowMoreJobs = ({
    params,
    numberOfJobsLeft,
}: {
    params: ParamsWithDefaults
    numberOfJobsLeft: number
}) => {
    const { filters, updateFilters } = useJobsSearchContext()
    const analytics = useAnalytics()
    const { page } = params
    const nextPage = page + 1

    const trackShowMoreButton = () => {
        analytics?.track('Show more jobs clicked', { pageNumber: page })
    }

    const handleShowMoreButtonClick = () => {
        trackShowMoreButton()
        updateFilters({ page: nextPage })
    }

    // NB: From UX standpoint, since we don’t have a pagination on the Jobsboard
    //     anymore, this is important to signal users how many jobs are left for
    //     a search query they've used.
    //     Also, don't push a new entry to the browser history (since the search
    //     params stay the same and this is the only thing that is important for
    //     navigation) and do not scroll a page to the top upon the button click.
    return (
        <Link
            href={createURL({ ...filters, page: nextPage })}
            passHref
            replace={true}
            scroll={false} // doesn't seem to work in latest Next.js (https://github.com/vercel/next.js/issues/36447)
            shallow={true}
        >
            <Button
                as="a"
                variant="secondary"
                size="lg"
                w="100%"
                mt="12px"
                onClick={handleShowMoreButtonClick}
            >
                {`Show more jobs (${numberOfJobsLeft})`}
            </Button>
        </Link>
    )
}

const buildQueryVariables = (
    pagination:
        | {
              page: number
              page_size: number
          }
        | {
              offset: number
              length: number
          },
    params: Params,
    vertical: StandardVertical,
    presetSearch: PresetSearch | undefined,
) => {
    const {
        search,
        tags,
        locationCity,
        locationRegion,
        locationCountryCode,
        remote,
        sponsorshipOffered,
        roleTypes,
        remunerationMin,
        remunerationCurrency,
        remunerationTimePeriod,
        companyPackages,
        manager,
        published,
    } = params

    const filters: SearchFilters = {
        remote,
        sponsorshipOffered,
        manager,
        published,
    }
    if (tags && tags.length > 0) {
        filters.tags = tags
    }
    if (roleTypes && roleTypes.length > 0) {
        filters.roleTypes = roleTypes
    }
    if (locationCity || locationRegion || locationCountryCode) {
        filters.location = {
            cities: locationCity,
            regions: locationRegion,
            countryCodes: locationCountryCode,
        }
    }
    if (remunerationMin || remunerationCurrency || remunerationTimePeriod) {
        filters.remuneration = {
            min: remunerationMin,
            currency: remunerationCurrency,
            timePeriod: remunerationTimePeriod,
        }
    }
    if (companyPackages) {
        filters.company = {
            packages: companyPackages,
        }
    }

    return merge(
        {
            vertical,
            search_term: search ? search.join(' ') : undefined,
            filters,
            preset_search: presetSearch?.urlFragment,
        },
        pagination,
    )
}

type PagesData = {
    pagesNum: number
    lastPageToRender: number
    lastPageWithCuratedJobs: number
    offsetAfterCurated: number
    roundNumberOfCurated: boolean
    numberOfJobsLeft: number
}

const PageWithJobs = ({
    page,
    params,
    vertical,
    jobsNum,
    pagesData,
}: {
    page: number
    params: Params
    vertical: StandardVertical
    jobsNum: JobsNumber
    pagesData: PagesData
}) => {
    const pagination = {
        offset: (page - 1) * CURATED_AND_PARTNER_PAGE_SIZE,
        length: CURATED_AND_PARTNER_PAGE_SIZE,
    }
    // We don't know how this works.
    // if (page === pagesData.lastPageWithCuratedJobs) {
    //     pagination.length = CURATED_AND_PARTNER_PAGE_SIZE + pagesData.offsetAfterCurated
    // } else {
    //     pagination.offset =
    //         (page - 1) * CURATED_AND_PARTNER_PAGE_SIZE + pagesData.offsetAfterCurated
    // }

    const { presetSearch } = useJobsSearchContext()
    const [{ data, fetching, error }] = useQuery({
        query: QUERY_SEARCH_JOBS,
        variables: buildQueryVariables(pagination, params, vertical, presetSearch),
        pause: pagination.offset >= jobsNum.total, // no need to fetch nothing
    })

    let curatedJobsTitle, partnerJobsTitle
    if (page === 1 && jobsNum.curated > 0) {
        curatedJobsTitle = `${contentSections.curated.title} (${formatNumber(
            jobsNum.curated,
        )})`
    }
    if (
        (!pagesData.roundNumberOfCurated && page === pagesData.lastPageWithCuratedJobs) ||
        (pagesData.roundNumberOfCurated && page === pagesData.lastPageWithCuratedJobs + 1)
    ) {
        partnerJobsTitle = `${contentSections.partner.title} (${formatNumber(
            jobsNum.partner,
        )})`
    }

    if (fetching) {
        const fakeTitle =
            curatedJobsTitle ??
            (pagesData.roundNumberOfCurated &&
            page === pagesData.lastPageWithCuratedJobs + 1
                ? partnerJobsTitle
                : undefined)
        const loadingPageSize =
            page === pagesData.pagesNum
                ? CURATED_AND_PARTNER_PAGE_SIZE + pagesData.numberOfJobsLeft
                : CURATED_AND_PARTNER_PAGE_SIZE
        return (
            <JobsGrid
                title={fakeTitle}
                loadingPageSize={loadingPageSize}
                loading={true}
            />
        )
    }

    if (error) {
        return null
    }

    const allJobs = data?.jobs_search?.jobs
    let curatedJobs = allJobs,
        partnerJobs: JobCardData[] = []
    if (page >= pagesData.lastPageWithCuratedJobs) {
        curatedJobs = allJobs?.filter(job => job.affiliate === null) ?? []
        partnerJobs = allJobs?.filter(job => job.affiliate !== null) ?? []
    }

    return (
        <>
            {curatedJobs && curatedJobs.length > 0 && (
                <JobsGrid
                    title={curatedJobsTitle}
                    jobs={curatedJobs}
                    loading={fetching}
                />
            )}
            {partnerJobs && partnerJobs.length > 0 && (
                <JobsGrid
                    title={partnerJobsTitle}
                    jobs={partnerJobs}
                    loading={fetching}
                />
            )}
        </>
    )
}

const calculatePagesData = (
    params: ParamsWithDefaults,
    pagesNum: number,
    jobsNum: JobsNumber,
): PagesData => {
    const lastPageToRender = Math.min(params.page, pagesNum)
    const lastPageWithCuratedJobs = Math.ceil(
        jobsNum.curated / contentSections.curated.pageSize,
    )
    // NB: Since the JobsGrid has more than 1 column on medium+ screens, we need
    //     to fetch some extra jobs for the last page with curated jobs in case
    //     we have a non-even amount of those with regards to the # of columns.
    const offsetAfterCurated = jobsNum.curated % COLUMNS_AT_MEDIUM_SCREEN_SIZE
    // NB: This is what necessary for proper partner jobs section title display.
    const roundNumberOfCurated = jobsNum.curated % CURATED_AND_PARTNER_PAGE_SIZE === 0
    // NB: This is the logic behind the "Show more jobs" button and it's label.
    const numberOfJobsLeft =
        jobsNum.total -
        lastPageToRender * CURATED_AND_PARTNER_PAGE_SIZE -
        (params.page >= lastPageWithCuratedJobs ? offsetAfterCurated : 0)
    return {
        pagesNum,
        lastPageToRender,
        lastPageWithCuratedJobs,
        offsetAfterCurated,
        roundNumberOfCurated,
        numberOfJobsLeft,
    }
}

const JobsBoard = ({
    params,
    vertical,
    loading,
    pagesNum,
    jobsNum,
}: {
    params: ParamsWithDefaults
    vertical: StandardVertical
    loading: boolean
    pagesNum: number
    jobsNum: JobsNumber
}) => {
    const noJobs = !loading && jobsNum.total === 0

    const pagesData = useMemo(() => {
        return calculatePagesData(params, pagesNum, jobsNum)
    }, [params, pagesNum, jobsNum])

    return (
        <Box px={{ base: '12px', md: '24px', lg: '36px' }}>
            {noJobs ? (
                <Box mb={{ base: '54px', md: '160px' }}>
                    <NoMatchingJob />
                    <OtherJobs />
                </Box>
            ) : (
                <Box
                    mt={{ base: '36px', md: '48px', lg: '64px' }}
                    mb={{ base: '54px', md: '76px' }}
                >
                    {loading ? (
                        <JobsGrid title={contentSections.curated.title} loading={true} />
                    ) : (
                        <>
                            {range(1, pagesData.lastPageToRender + 1).map(p => {
                                return (
                                    <PageWithJobs
                                        key={`page-with-jobs-${p}`}
                                        page={p}
                                        params={params}
                                        vertical={vertical}
                                        jobsNum={jobsNum}
                                        pagesData={pagesData}
                                    />
                                )
                            })}
                            {pagesData.numberOfJobsLeft > 0 && (
                                <ShowMoreJobs
                                    params={params}
                                    numberOfJobsLeft={pagesData.numberOfJobsLeft}
                                />
                            )}
                        </>
                    )}
                </Box>
            )}
        </Box>
    )
}

export const JobsBoardPage = () => {
    const containerWidth = useToken('breakpoints', 'jobsboard_container')
    const { presetSearch } = useJobsSearchContext()
    const { params } = useParams(presetSearch?.searchParams)
    const vertical = useVertical()
    const me = useMe()

    const [{ data, fetching }] = useQuery({
        query: QUERY_SEARCH_JOBS,
        variables: buildQueryVariables(
            {
                page: 1,
                page_size: CURATED_AND_PARTNER_PAGE_SIZE,
            },
            params,
            vertical,
            presetSearch,
        ),
    })

    const pagesNum = data?.jobs_search?.numberOfPages ?? 0
    const jobsNum = getJobsNumber(data?.jobs_search.facets)

    return (
        <AppShell
            title="Jobs Board"
            contentMaxWidth={containerWidth}
            backgroundColor="gray.background"
        >
            <SeoJobsBoard presetSearch={presetSearch} />
            <JobsBoardHeader
                title={presetSearch?.title}
                facets={data?.jobs_search.facets}
                cityInfo={data?.city_info}
                loading={fetching}
            />
            <AdminFilters />
            <Box
                px={{ base: '12px', md: '24px', lg: '36px' }}
                mt={'20px'}
                display={!me || me.isCompanyUser ? 'block' : 'none'}
            >
                <CompanyPromoBanner />
            </Box>
            <WithLikedJobs>
                <JobsBoard
                    params={params}
                    vertical={vertical}
                    loading={fetching}
                    pagesNum={pagesNum}
                    jobsNum={jobsNum}
                />
            </WithLikedJobs>
        </AppShell>
    )
}
