import React from 'react'
import { NextComponentType, NextPageContext } from 'next'
import { ParsedUrlQuery } from 'querystring'
import { pickBy } from 'ramda'
import { findPresetJobsLocation } from 'entities/location'
import { AlgoliaTag, AllTagTypes } from 'entities/tag'
import { notNullOrUndefined } from 'lib/predicates'
import {
    buildPresetSearchUrlFragment,
    buildPresetSearchTitle,
    PresetSearchEntity,
} from 'lib/presetSearch'
import { capitalize } from 'lib/text'
import { decodeValue, decoders } from 'lib/url'
import { getAlgoliaIndex } from 'hooks/useAlgoliaIndex'
import {
    JobsSearchContextProvider,
    PresetSearch,
} from 'containers/jobs-board/context/JobsSearchContext'
import { SearchParams } from 'containers/jobs-board/hooks/useParams'

type PresetSearchParams = {
    tech?: string
    location?: string
    remote?: true
}

const psQueryParamName = {
    tech: 'psTech',
    location: 'psLocation',
    remote: 'psRemote',
} as const

export const parsePresetSearchParams = (query: ParsedUrlQuery) => {
    // NB: The Router's `query` comes parsed but its props are still URL-encoded.
    return {
        tech: decodeValue(query[psQueryParamName.tech], decoders.string),
        location: decodeValue(query[psQueryParamName.location], decoders.string),
        remote: decodeValue(query[psQueryParamName.remote], decoders.boolean),
    } as PresetSearchParams
}

const findTagBySlug = async (slug: string) => {
    return await getAlgoliaIndex('tags')
        .search<AlgoliaTag>(slug, {
            filters: `type:${AllTagTypes.tech}`,
            restrictSearchableAttributes: ['slug'],
            attributesToRetrieve: ['slug', 'label'],
            typoTolerance: false,
        })
        .then(res => res.hits.find(tag => tag.slug === slug))
}

const getPresetSearch = async (
    config: { entity: PresetSearchEntity },
    query: ParsedUrlQuery,
) => {
    const presetSearchParams = parsePresetSearchParams(query)

    const urlFragment = buildPresetSearchUrlFragment(
        {
            tech: presetSearchParams.tech ? { slug: presetSearchParams.tech } : undefined,
            location: presetSearchParams.location
                ? { slug: presetSearchParams.location }
                : undefined,
            remote: presetSearchParams.remote,
        },
        config.entity,
    )
    if (urlFragment === undefined) {
        return undefined
    }

    let techLabel
    if (presetSearchParams.tech) {
        // NB: This guarantees bulletproof results, even if there's no such tag.
        const techTag = await findTagBySlug(presetSearchParams.tech)
        techLabel = techTag?.label ?? capitalize(presetSearchParams.tech)
    }
    const title = buildPresetSearchTitle(
        {
            techLabel: techLabel,
            locationSlug: presetSearchParams.location,
            remote: presetSearchParams.remote,
        },
        config.entity,
    )
    const location = findPresetJobsLocation(presetSearchParams.location)
    const searchParams: SearchParams = pickBy(notNullOrUndefined, {
        tags: techLabel ? [techLabel] : undefined,
        locationCity: location?.city ? [location?.city] : undefined,
        locationCountryCode: location?.countryCode ? [location?.countryCode] : undefined,
        remote: presetSearchParams.remote,
    })

    const presetSearch: PresetSearch = {
        urlFragment,
        title,
        searchParams,
    }

    return presetSearch
}

type PresetSearchConfig = { entity: PresetSearchEntity } | { turnedOff: true }

export const withPresetSearch = (config: PresetSearchConfig) => (
    Component: NextComponentType,
) => {
    const WrappedComponent = ({
        presetSearch,
        ...props
    }: { presetSearch?: PresetSearch } & any) => {
        return (
            <JobsSearchContextProvider presetSearch={presetSearch}>
                <Component {...props} />
            </JobsSearchContextProvider>
        )
    }

    WrappedComponent.getInitialProps = async (ctx: NextPageContext) => {
        const otherProps =
            Component.getInitialProps && (await Component.getInitialProps(ctx))

        if ('turnedOff' in config) {
            return {
                ...otherProps,
            }
        }

        // NB: We need to run it only once, since any changes in filters
        //     or parameters will result in a separate non-preset search.
        const presetSearch = await getPresetSearch(config, ctx.query)

        return {
            ...otherProps,
            presetSearch,
        }
    }

    return WrappedComponent
}
