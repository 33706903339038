import { gql } from '@urql/core'
import { VerticalAssociationTag } from 'entities/tag'
import { StandardVertical } from 'entities/vertical'

export type AssociatedTagsResult = { results: VerticalAssociationTag[] }

export const QUERY_ASSOCIATED_TAGS = gql<
    { associated_tags: AssociatedTagsResult, limit?: number },
    { vertical: StandardVertical }
>`
    query associated_tags($vertical: vertical!, $limit: Int) {
        associated_tags(vertical: $vertical, limit: $limit) {
            results {
                id
                label
            }
        }
    }
`

export const MUTATION_ASSOCIATE_TAGS = gql<
    { associate_tags: AssociatedTagsResult & { success: boolean } },
    { vertical: StandardVertical; tagIds?: string[] }
>`
    mutation($vertical: vertical!, $tagIds: [ID]) {
        associate_tags(vertical: $vertical, tag_ids: $tagIds) {
            results {
                id
                label
            }
            success
        }
    }
`
