import React from 'react'
import { NextSeo, NextSeoProps } from 'next-seo'
import Head from 'next/head'
import { AboutWorkshub } from 'components/seo/AboutWorkshub'

interface Twitter {
    card: string
    site: string
    creator: string
    pairs: [string, string][]
}

interface MetaTag {
    content: string
    name: string
}

const transformTwitterPairs = (pairs: [string, string][]) => {
    return pairs
        .map(([label, value], idx) => [
            { name: 'label' + (idx + 1), content: label },
            { name: 'value' + (idx + 1), content: value },
        ])
        .flat()
}

const twitterMetaTags = (twitter?: Twitter) => {
    return (
        twitter &&
        [
            {
                name: 'card',
                content: twitter.card,
            },
            {
                name: 'site',
                content: twitter.site,
            },
            {
                name: 'creator',
                content: twitter.creator,
            },
            ...transformTwitterPairs(twitter.pairs),
        ].map(obj => ({ ...obj, name: 'twitter:' + obj.name }))
    )
}

export const StructuredData = ({ structuredData }: { structuredData?: object }) => {
    if (structuredData) {
        return (
            <Head>
                <script
                    id={'structured-data'}
                    type={'application/ld+json'}
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
                />
            </Head>
        )
    }

    return null
}

export const Seo = ({
    keywords,
    twitter,
    structuredData,
    author,
    ...props
}: Omit<NextSeoProps, 'twitter'> & {
    keywords?: string[]
    twitter?: Twitter
    structuredData?: object
    author?: string
}) => {
    const keywordsMetaTag = keywords && {
        name: 'keywords',
        content: keywords.join(', '),
    }

    const authorMetaTag = author
        ? {
              name: 'author',
              content: author,
          }
        : undefined

    const metaTags: (MetaTag | undefined)[] = [
        keywordsMetaTag,
        authorMetaTag,
        ...(twitterMetaTags(twitter) ?? []),
    ]

    const additionalMetaTags = metaTags.filter((t): t is MetaTag => !!t)

    return (
        <>
            <NextSeo {...props} additionalMetaTags={additionalMetaTags} />
            <StructuredData structuredData={structuredData} />
            <AboutWorkshub />
        </>
    )
}
