import { Box, BoxProps, Text, Flex, FlexProps, chakra } from '@chakra-ui/react'
import Image from 'next/image'
import Link from 'next/link'
import { ArrowRight } from '@emotion-icons/heroicons-outline/ArrowRight'
import { Me } from 'entities/user'
import { routes } from 'routes'
import useMe from 'hooks/useMe'

const ArrowRightIcon = chakra(ArrowRight)

const StarIcon = (props: BoxProps) => {
    const w = 38
    const h = 45
    return (
        <Box position={'absolute'} top={'0'} right={'0'} width={w} height={h} {...props}>
            <Image src={'/img/star3_icon.svg'} width={w} height={h} alt={'star icon x'} />
        </Box>
    )
}

const BrandIcon = (props: BoxProps & { url: string }) => {
    const w = 56
    const h = 56
    return (
        <Box {...props}>
            <Image src={props.url} width={w} height={h} alt={'brand icon'} />
        </Box>
    )
}

const textColour = '#4853C8'

const route = (currentUser: Me) => {
    if (currentUser?.isCompanyUser) {
        return routes.newJob()
    } else {
        return routes.companyRegistration()
    }
}

export const CompanyPromoBanner = (flexProps: FlexProps) => {
    const currentUser = useMe()
    return (
        <Link href={route(currentUser)} passHref replace={true} shallow={true}>
            <Flex
                cursor={'pointer'}
                h={'72px'}
                w={'100%'}
                borderRadius={'16px'}
                bgGradient={'linear(to-b, #6c77ed80, #FFFFFF)'}
                overflow={'hidden'}
                position={'relative'}
                {...flexProps}
            >
                {/* Background */}
                <Flex
                    flex={'0'}
                    position={'absolute'}
                    right={0}
                    display={{ base: 'none', md: 'block' }}
                >
                    <StarIcon
                        right={'568px'}
                        top={'-18px'}
                        transform={'rotate(-29.548deg)'}
                    />
                    <StarIcon
                        right={'463px'}
                        top={'40px'}
                        transform={'rotate(13.994deg)'}
                    />
                    <StarIcon
                        right={'364px'}
                        top={'-18px'}
                        transform={'rotate(13.479deg)'}
                    />
                    <StarIcon
                        right={'20px'}
                        top={'42px'}
                        transform={'rotate(12.071deg)'}
                    />
                </Flex>

                <Flex
                    alignItems={'center'}
                    w={'100%'}
                    justifyContent={{ base: 'start', md: 'space-between' }}
                    zIndex={0} // this puts the stars behind the text
                >
                    {/* Text */}
                    <Flex
                        justifyContent={'start'}
                        alignItems={'center'}
                        flex={'1 0 auto'}
                        pl={{ base: '6px', xxs: '26px' }}
                    >
                        <Text
                            fontSize={{ base: '22px', md: '24px' }}
                            fontWeight={'600'}
                            lineHeight={'34px'}
                            letterSpacing={'-0.24px'}
                            color={textColour}
                        >
                            Post your 1st job for free
                        </Text>
                        <Text
                            fontSize={{ base: '22px', md: '24px' }}
                            fontWeight={'600'}
                            lineHeight={'34px'}
                            letterSpacing={'-0.24px'}
                            color={textColour}
                            display={{ base: 'none', lg: 'block' }}
                        >
                            &nbsp;and access top talent
                        </Text>
                        <ArrowRightIcon ml={'8px'} color={textColour} boxSize={'20px'} />
                    </Flex>
                    {/* Brand Logos */}
                    <Flex
                        transform={{ base: 'scale(0.75)', md: 'none' }}
                        ml={{ base: '-42px', md: 'unset' }}
                    >
                        <BrandIcon
                            url={'/img/brands/hopper.svg'}
                            mt={'-4px'}
                            mr={'14px'}
                        />
                        <BrandIcon url={'/img/brands/spotify.svg'} mt={'24px'} />
                        <BrandIcon url={'/img/brands/hinge.svg'} mt={'-8px'} mr={'7px'} />
                        <BrandIcon
                            url={'/img/brands/aws.svg'}
                            mt={'23px'}
                            mr={{ base: '2px', md: '34px' }}
                        />
                        <BrandIcon
                            url={'/img/brands/airbnb.svg'}
                            mt={{ base: '-4px', md: '24px' }}
                        />
                        <BrandIcon
                            url={'/img/brands/disney.svg'}
                            mt={{ base: '24px', md: '-19px' }}
                            mr={'1px'}
                        />
                        <BrandIcon url={'/img/brands/nubank.svg'} mt={'-4px'} />
                    </Flex>
                </Flex>
            </Flex>
        </Link>
    )
}
