// TODO: Fill up the gaps in data (`perks`, `startingPrice`).
export const Packages = {
    Explore: {
        name: 'Explore',
        gqlName: 'explore',
    },
    LaunchPad: {
        name: 'Launch Pad',
        perks: [
            'Unlimited jobs',
            'Personal account manager',
            'Employer branding solutions',
        ],
        startingPrice: '$360',
        gqlName: 'launch_pad',
    },
    TakeOff: {
        name: 'Take Off',
        gqlName: 'take_off',
    },
} as const
