import { Box, Flex, Text, Skeleton } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import { useVerticalConfig } from 'hooks/useVerticalData'
import {
    CityInfo,
    cityInfoToCountryCodeToCountryName,
    Facet,
} from 'containers/jobs-board/components/options'
import { CardWrapper } from 'components/CardWrapper'
import { Heading } from 'components/Heading'

const LazyFilters = dynamic(() => import('containers/jobs-board/components/Filters'), {
    loading: () => <LazyLoadingSkeleton />,
    ssr: false,
})

const LazyLoadingSkeleton = () => {
    return (
        <Flex mx={{ base: '12px', md: '50px' }} justifyContent="center">
            <Box w="100%" maxWidth={{ lg: '886px' }}>
                <Skeleton h={{ base: '54px', md: '70px' }} borderRadius="12px" />
                <Flex gridGap="10px" mt="16px" display={{ base: 'none', md: 'flex' }}>
                    {[1, 2, 3, 4, 5].map(val => (
                        <Skeleton h="42px" flex={1} borderRadius="12px" key={val} />
                    ))}
                </Flex>
                <Flex gridGap="10px" mt="16px" display={{ base: 'flex', md: 'none' }}>
                    {[1, 2, 3].map(val => (
                        <Skeleton
                            h="42px"
                            flex={1}
                            borderRadius="12px"
                            key={`mobile-${val}`}
                        />
                    ))}
                </Flex>
            </Box>
        </Flex>
    )
}

export const JobsBoardHeader = ({
    title: customTitle,
    facets,
    cityInfo,
    loading,
}: {
    title?: string
    facets?: Facet[]
    cityInfo?: CityInfo[]
    loading: boolean
}) => {
    const verticalConfig = useVerticalConfig()
    const {
        title: verticalTitle,
        mobileTitle: verticalMobileTitle,
    } = verticalConfig.jobsboardHeader

    const countryCodeToCountryName = cityInfoToCountryCodeToCountryName(cityInfo)

    return (
        <Box
            as={CardWrapper}
            justifyContent={{ md: 'center' }}
            alignItems={{ lg: 'center' }}
            px="0"
            pt={{ base: '24px', md: '48px' }}
            pb={{ base: '24px', md: '54px' }}
            borderRadius={{ base: '0px', lg: '12px' }}
            borderWidth={{ base: '0px', lg: '1px' }}
            position="relative"
            mt={{ lg: '36px' }}
            mx={{ lg: '36px' }}
        >
            <Box px={{ base: '12px', md: '50px', lg: '72px' }}>
                <Heading as="h1" size="lg" display={{ base: 'none', md: 'block' }}>
                    Browse{' '}
                    <Text
                        as="span"
                        fontSize="inherit"
                        lineHeight="inherit"
                        letterSpacing="inherit"
                        fontWeight="inherit"
                        color={customTitle && 'secondary.500'}
                    >
                        {customTitle ?? verticalTitle}
                    </Text>
                </Heading>
                <Heading as="h1" size="md" display={{ base: 'block', md: 'none' }}>
                    Browse{' '}
                    <Text
                        as="span"
                        fontSize="inherit"
                        lineHeight="inherit"
                        letterSpacing="inherit"
                        fontWeight="inherit"
                        color={customTitle && 'secondary.500'}
                    >
                        {customTitle ?? verticalMobileTitle ?? verticalTitle}
                    </Text>
                </Heading>
            </Box>
            <Box w="full" mt={{ base: '20px', md: '24px' }}>
                <LazyFilters
                    countryCodeToCountryName={countryCodeToCountryName}
                    facets={facets}
                    loading={loading}
                />
            </Box>
        </Box>
    )
}
